<template>
  <div>
    <h1>{{ id ? '修改' : '新建' }}权限</h1>
    <el-form @submit.native.prevent="save">
      <div class="el-form--max-width-400">
        <el-form-item label="权限名">
          <el-input v-model="model.name" />
        </el-form-item>
        <el-form-item label="URL">
          <el-input v-model="model.url" />
        </el-form-item>
      </div>
      <el-form-item label="请求方法">
        <el-select v-model="model.method">
          <el-option
            v-for="method in methods"
            :key="method"
            :label="method"
            :value="method"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="父级权限">
        <el-cascader
          v-model="model.parent_id"
          :options="permissions"
          :props="{
            value: 'id',
            label: 'name',
            checkStrictly: true,
            emitPath: false,
          }"
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { data2tree } from '@/utils/tree'
import { methods } from '@/config/methods'
export default {
  props: {
    id: String,
  },
  data() {
    return {
      model: {
        name: '',
        url: '',
        method: '',
        parent_id: '',
      },
      permissions: [],
      methods,
    }
  },
  created() {
    this.id && this.fetch()
    this.fetchPermissions()
  },
  methods: {
    async fetch() {
      const { data: permission } = await this.$http.get(
        `permissions/${this.id}`,
      )
      this.model = Object.assign(this.model, permission)
    },
    async fetchPermissions() {
      const { data: permissions } = await this.$http.get(
        'permissions?paginate=0&order=asc',
      )
      this.permissions = data2tree(permissions, null)
    },
    async save() {
      if (this.id) {
        await this.$http.put(`permissions/${this.id}`, this.model)
      } else {
        await this.$http.post('permissions', this.model)
      }
      this.$router.push('/permission/list')
      this.$message.success('保存成功')
    },
  },
}
</script>
